import React ,{useEffect}from 'react'
import { Link } from 'react-router-dom'

export default function HomePage() {
    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
  return (
    <div>
          <>
              <div className="ps-home ps-home--1">
                  <section className="ps-section--banner">
                      <div className="ps-section__overlay">
                          <div className="ps-section__loading" />
                      </div>
                      <div
                          className="owl-carousel"
                          data-owl-auto="false"
                          data-owl-loop="true"
                          data-owl-speed={15000}
                          data-owl-gap={0}
                          data-owl-nav="true"
                          data-owl-dots="false"
                          data-owl-item={1}
                          data-owl-item-xs={1}
                          data-owl-item-sm={1}
                          data-owl-item-md={1}
                          data-owl-item-lg={1}
                          data-owl-duration={1000}
                          data-owl-mousedrag="on"
                      >
                          <div className="ps-banner" style={{ background: "#BABABA" }}>
                              <div className="ps-banner__thumnail">
                                  <img
                                      src="assets/img/banners/banner1.jpg"
                                      alt="Computer Institute in Moradabad"
                                  />
                              </div>
                          </div>
                          <div className="ps-banner" style={{ background: "#BABABA" }}>
                              <div className="ps-banner__thumnail">
                                  <img
                                      src="assets/img/banners/banner3.jpg"
                                      alt="Computer Center in Moradabad"
                                  />
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
              <section className="ps-category--icon">
                  <div className="container">
                      <div className="ps-category__content">
                          <div className="ps-category__item">
                              {" "}
                              <Link
                                  to="/Website-Designing-Company-Moradabad"
                                  className="ps-category__thumbnail"
                              >
                                  <img
                                      alt="Web Development"
                                      src="assets/img/images/webdes.jpg"
                                      style={{ height: "58px" }}
                                  />
                              </Link>
                              <p className="ps-category__name fontsize">
                                  <a>Website Development</a>
                              </p>
                          </div>
                          <div className="ps-category__item">
                              <Link
                                  to="/Software-Company-Moradabad"
                                  className="ps-category__thumbnail"
                              >
                                  <img
                                      alt="Olevel Computer Course Moradabad"
                                      src="assets/img/images/Software-Development.jpg"
                                      style={{ height: "58px" }}
                                  />
                              </Link>
                              <p className="ps-category__name fontsize">
                                  <a>Software Development</a>
                              </p>
                          </div>
                          <div className="ps-category__item">
                              <Link
                                  to="/Digital-Marketing-Moradabad"
                                  className="ps-category__thumbnail"
                              >
                                  <img
                                      alt="ADCA Computer Course Moradabad"
                                      src="assets/img/images/digital-agency.jpg"
                                      style={{ height: "58px" }}
                                  />
                              </Link>
                              <p className="ps-category__name fontsize">
                                  <a>Digital Marketing</a>
                              </p>
                          </div>
                          <div className="ps-category__item">
                              {" "}
                              <Link
                                  to="/Recruitment-Company-Moradabad"
                                  className="ps-category__thumbnail"
                              >
                                  <img
                                      alt="C Language Computer Course Moradabad"
                                      src="assets/img/images/recruitment.jpg"
                                  />
                              </Link>
                              <p className="ps-category__name fontsize">
                                  <a>HR Recruitment</a>
                              </p>
                          </div>
                         
                         
                         
                      </div>
                  </div>
              </section>
              <section className="ps-banner--content">
                  <div className="container">
                      <center>
                          <h1
                              className="ps-btn ps-btn--rounded ps-btn--danger"
                              style={{ fontSize: "25px" }}
                          >
                              Chakshu Infotech Company Moradabad
                          </h1>
                      </center>
                      <br />
                      <div className="ps-banner" style={{ background: "#BCBCBC" }}>
                          <div>
                              <img
                                  alt="Computer Center in Moradabad"
                                  width="100%"
                                  src="assets/img/banner1.png"
                              />
                          </div>
                      </div>
                  </div>
              </section>
              <section className="ps-category--section">
                  <div className="container">
                      <div className="ps-category__grid">
                          <div className="ps-category--item">
                              <br />
                              <div className="ps-category__content">
                                  <p className="ps-category__type">
                                      <b>BEST SOFTWARE COMPANY IN MORADABAD</b>
                                  </p>
                                  <p>
                                      Chakshu Infotech is a <b>software development company in Moradabad</b> that works in a flexible environment for best software development service in Moradabad process, adjusted as per the requirements of our clients. We have skilled professionals to create highly functional websites and market them to be successful. We go the extra mile to understand your needs. We match our processes to your preferences so you can be confident about our work. We are experts in various fields like Website Designing, Mobile Apps Development, Search Engine Optimization, Domain Solutions, Content Management System, Website Redesigning or Development, Custom Software Development, etc. Quality work is a prerequisite for everything we do at Chakshu Software as we believe that "Every Day Counts". and why not; We know that good jobs always bring new customers. etc. We take personal responsibility for achieving ambitious, measurable results in pursuit of our vision.
                                      {/* <b>
                                          Accounting, Designing, Programming, Internet, Database
                                          Management
                                      </b>
                                      etc. In PKS Computer Center Professional teachers are available
                                      with having Expertise in Computer Latest Software Applications
                                      from Basic to Advanced level of Knowledge. Our Center Cover mainly
                                      all computer courses like
                                      <b>CCC, O-LEVEL, ADCA, DCA, DTP, TALLY, MS-OFFICE, PHOTOSHOP</b> */}
                                  </p>
                              </div>
                              <div className="ps-category__content">
                                  <p className="ps-category__type">
                                      <b>SOFTWARE COMPANY IN MORADABAD</b>
                                  </p>
                                  <p>
                                      Chakshu Infotech is a leading IT services and digital marketing company based in Moradabad, India. Customer satisfaction is the driving force that drives the company towards its goals while facing the challenges of the times. Chakshu Software is the best software company in Moradabad that provides professional level customized solutions, software development in Moradabad, website designing in Moradabad and digital agency company in Moradabad.
                                  </p>
                              </div>
                          </div>
                          <div className="ps-category--item">
                              <div className="ps-category">
                                  {" "}
                                  <img
                                      src="assets/img/brochure1.png"
                                      alt="Computer Course Moradabad"
                                      style={{ height: "100%", width: "100%" }}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section className="ps-section--default ps-product--carousel">
                  <div className="container">
                      <br />
                      <h2 className="ps-section__title">Our Services</h2>
                      <div className="ps-section__carousel">
                          <div
                              className="owl-carousel"
                              data-owl-auto="true"
                              data-owl-loop="false"
                              data-owl-speed={13000}
                              data-owl-gap={30}
                              data-owl-nav="true"
                              data-owl-dots="false"
                              data-owl-item={4}
                              data-owl-item-xs={2}
                              data-owl-item-sm={2}
                              data-owl-item-md={3}
                              data-owl-item-lg={4}
                              data-owl-item-xl={4}
                              data-owl-duration={1000}
                              data-owl-mousedrag="on"
                          >
                              <div className="ps-product ps-product--standard">
                                  <div className="ps-product__thumbnail">
                                      <Link
                                          className="ps-product__image"
                                          to="/Website-Designing-Company-Moradabad"
                                      >
                                          <figure>
                                              <img
                                                  className="ps-product__image-default img_height"
                                                  src="assets/img/images/webdes.jpg"
                                                  alt="alt"
                                                  style={{height:"123px"}}
                                              />
                                              <img
                                                  className="ps-product__image-back"
                                                  src="assets/img/images/webdes.jpg"
                                                  alt="DCA Computer Course Moradabad"
                                                  style={{ height: "123px" }}
                                              />
                                          </figure>
                                      </Link>
                                      <Link
                                          className="ps-btn ps-btn--dark ps-product__cart"
                                          title="DCA Computer Course in Moradabad"
                                          to="/Website-Designing-Company-Moradabad"
                                      >
                                          Website Development
                                      </Link>
                                      <p>
                                          Expert website development services creating responsive, user-friendly sites with HTML, CSS, JavaScript, SEO optimization, and cutting-edge design. Affordable, reliable, and customizable.
                                      </p>
                                  </div>
                              </div>
                              <div className="ps-product ps-product--standard">
                                  <div className="ps-product__thumbnail">
                                      <Link
                                          className="ps-product__image"
                                          to="/Software-Company-Moradabad"
                                      >
                                          <figure>
                                              <img
                                                  className="ps-product__image-default img_height"
                                                  src="assets/img/images/Software-Development.jpg"
                                                  alt="Software Company in Moradabad"
                                              />
                                              <img
                                                  className="ps-product__image-back"
                                                  src="assets/img/images/Software-Development.jpg"
                                                  alt="DTP Computer Course Moradabad"
                                              />
                                          </figure>
                                      </Link>
                                      <Link
                                          className="ps-btn ps-btn--dark ps-product__cart"
                                          to="/Software-Company-Moradabad"
                                          title="Software Company in Moradabad"
                                      >
                                        Software Development
                                      </Link>
                                      <p className="uk-margin-remove-top">
                                          Efficient software development using Agile methodologies and cutting-edge technologies for seamless, scalable, and user-centric applications.
                                      </p>
                                  </div>
                              </div>
                              <div className="ps-product ps-product--standard">
                                  <div className="ps-product__thumbnail">
                                      <Link
                                          className="ps-product__image"
                                          to="/Digital-Marketing-Moradabad"
                                      >
                                          <figure>
                                              <img
                                                  className="ps-product__image-default img_height"
                                                  src="assets/img/images/digital-agency.jpg"
                                                  alt="alt"
                                                  style={{ height: "148px" }}
                                              />
                                              <img
                                                  className="ps-product__image-back"
                                                  src="assets/img/images/digital-agency.jpg"
                                                  alt="Digital Marketing In Moradabad"
                                                  style={{ height: "148px" }}
                                              />
                                          </figure>
                                      </Link>
                                      <Link
                                          className="ps-btn ps-btn--dark ps-product__cart"
                                          to="/Digital-Marketing-Moradabad"
                                          title="DCFA Computer Course in Moradabad"
                                      >
                                          Digital Marketing
                                      </Link>
                                      <p className="uk-margin-remove-top">
                                          Maximize online presence with expert digital marketing strategies. SEO, social media, PPC, analytics, and conversion optimization for exceptional results
                                      </p>
                                  </div>
                              </div>
                              <div className="ps-product ps-product--standard">
                                  <div className="ps-product__thumbnail">
                                      <Link
                                          className="ps-product__image"
                                          to="/Recruitment-Company-Moradabad"
                                      >
                                          <figure>
                                              <img
                                                  className="ps-product__image-default img_height"
                                                  src="assets/img/images/recruitment.jpg"
                                                  alt="alt"
                                                  style={{ height: "148px" }}
                                              />
                                              <img
                                                  className="ps-product__image-back"
                                                  src="assets/img/images/recruitment.jpg"
                                                  alt="Python Programming Computer Course Moradabad"
                                                  style={{ height: "148px" }}
                                              />
                                          </figure>
                                      </Link>
                                      <Link
                                          className="ps-btn ps-btn--dark ps-product__cart"
                                          to="/Recruitment-Company-Moradabad"
                                          title="PYTHON Computer Course in Moradabad"
                                      >
                                          HR Recruitment
                                      </Link>
                                      <p className="uk-margin-remove-top">
                                          Expert recruitment services delivering top talent for your business needs. Digital Marketing: recruitment, services, expert, top talent, business.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <br/>
             
              <section className="ps-category--section">
                  <div className="container">
                      <div className="ps-category__grid">
                          <div className="ps-category--item">
                              <form>
                                  <div className="ps-form--contact">
                                      <h2 className="ps-form__title">
                                          Do you need to query about something? You can contact us
                                      </h2>
                                      <div className="row">
                                          <div className="col-12 col-md-4">
                                              <div className="ps-form__group">
                                                  <input
                                                      type="text"
                                                      id="txtname"
                                                      className="form-control ps-form__input"
                                                      placeholder="Name"
                                                  />
                                              </div>
                                          </div>
                                          <div className="col-12 col-md-4">
                                              <div className="ps-form__group">
                                                  <input
                                                      type="text"
                                                      id="txtphone"
                                                      className="form-control ps-form__input"
                                                      placeholder="Phone"
                                                  />
                                              </div>
                                          </div>
                                          <div className="col-12 col-md-4">
                                              <div className="ps-form__group">
                                                  <input
                                                      type="text"
                                                      id="txtemail"
                                                      className="form-control ps-form__input"
                                                      placeholder="Email"
                                                  />
                                              </div>
                                          </div>
                                          <div className="col-12">
                                              <div className="ps-form__group">
                                                  <textarea
                                                      id="txtmessage"
                                                      className="form-control ps-form__input"
                                                      placeholder="Message"
                                                      defaultValue={""}
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="ps-form__submit">
                                          <button
                                              onclick="sendemail()"
                                              className="ps-btn ps-btn--rounded"
                                          >
                                              Send
                                          </button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                          <div className="ps-category--item">
                              <div className="ps-contact__map">
                                  {" "}
                                  <div className="mapouter">
                                      <div className="gmap_canvas">
                                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13975.660205462338!2d78.73983057185984!3d28.871144664871885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390aff6c91f604c5%3A0x4558b02625a6211b!2sChakshu%20Infotech!5e0!3m2!1sen!2sin!4v1701613178800!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                          <br />
                                          <style
                                              dangerouslySetInnerHTML={{
                                                  __html:
                                                      ".mapouter{position:relative;text-align:right;height:400px;width:100%;}"
                                              }}
                                          />
                                          <a href="https://www.embedgooglemap.net/" />
                                          <style
                                              dangerouslySetInnerHTML={{
                                                  __html:
                                                      ".gmap_canvas {overflow:hidden;background:none!important;height:400px;width:100%;}"
                                              }}
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </>

    </div>
  )
}
