import React,{useEffect} from 'react'

export default function Contact() {
    useEffect(() => {
        
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    document.title = "Chakshu Infotech :Software Company In Moaradabad"

  return (
    <div>
          <div className="ps-contact">
              <div className="container">
                  <ul className="ps-breadcrumb">
                      <li className="ps-breadcrumb__item">
                          <a href="/">Home</a>
                      </li>
                      <li className="ps-breadcrumb__item">
                          <a className="active" aria-current="page" href="#">
                              Contact us
                          </a>
                      </li>
                  </ul>
                  <form>
                      <div className="ps-form--contact">
                          <h2 className="ps-form__title">
                              Do you need to query about something? You can contact us
                          </h2>
                          <div className="row">
                              <div className="col-12 col-md-4">
                                  <div className="ps-form__group">
                                      <input
                                          type="text"
                                          id="txtname"
                                          className="form-control ps-form__input"
                                          placeholder="Name"
                                      />
                                  </div>
                              </div>
                              <div className="col-12 col-md-4">
                                  <div className="ps-form__group">
                                      <input
                                          type="text"
                                          id="txtphone"
                                          className="form-control ps-form__input"
                                          placeholder="Phone"
                                      />
                                  </div>
                              </div>
                              <div className="col-12 col-md-4">
                                  <div className="ps-form__group">
                                      <input
                                          type="text"
                                          id="txtemail"
                                          className="form-control ps-form__input"
                                          placeholder="Email"
                                      />
                                  </div>
                              </div>
                              <div className="col-12">
                                  <div className="ps-form__group">
                                      <textarea
                                          id="txtmessage"
                                          className="form-control ps-form__input"
                                          placeholder="Message"
                                          defaultValue={""}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="ps-form__submit">
                              <button onclick="sendemail()" className="ps-btn ps-btn--rounded">
                                  Send
                              </button>
                          </div>
                      </div>
                  </form>
              </div>
              <div className="ps-contact__map">
                  {" "}
                  <div className="mapouter">
                      <div className="gmap_canvas">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13975.660205462338!2d78.73983057185984!3d28.871144664871885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390aff6c91f604c5%3A0x4558b02625a6211b!2sChakshu%20Infotech!5e0!3m2!1sen!2sin!4v1701613178800!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                          <a href="https://123movies-to.org/" />
                          <br />
                          <style
                              dangerouslySetInnerHTML={{
                                  __html:
                                      ".mapouter{position:relative;text-align:right;height:400px;width:100%;}"
                              }}
                          />
                          <a href="https://www.embedgooglemap.net/" />
                          <style
                              dangerouslySetInnerHTML={{
                                  __html:
                                      ".gmap_canvas {overflow:hidden;background:none!important;height:400px;width:100%;}"
                              }}
                          />
                      </div>
                  </div>
              </div>
          </div>

    </div>
  )
}
