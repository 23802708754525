import React,{useEffect} from  'react'

export default function Recruitment() {
    document.title = "RECRUITMENT-COMPANY-MORADABAD"
    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (

        <div>
            <div className="ps-blog ps-blog--layout1">
                <div className="container">

                    <h1 className="ps-blog__title">RECRUITMENT SERVICES MORADABAD</h1>
                    <div className="row">
                        <div className="col-12">
                            <section className="ps-post--grid">
                                <div className="ps-blog__banner">
                                    <img style={{ width: "100%",height:"300px" }}
                                        src="assets/img/images/recruitment.jpg"
                                        alt="RECRUITMENT SERVICES MORADABAD"
                                    
                                    />
                                </div>
                                <div class="ps-post__content">
                                    <p class="ps-blog__text-large">Chakshu infotech Digital Agency, your one-stop destination for all your digital marketing needs. We are a passionate team of creative professionals dedicated to helping businesses of all sizes thrive in the digital landscape. Our mission is to empower your onpne success through innovative strategies, cutting-edge technology, and unparalleled expertise.
                                        <br />

                                    </p>
                                    <h2>Welcome to Chakshu infotech Recruitment Services!</h2>
                                    <p>Are you seeking the best and brightest minds to join your organization? Look no further than Chakshu Infotech, the premier recruitment services provider, dedicated to connecting businesses with top-tier talent.
                                        <br /><br />
                                    </p>

                                    <h4>
                                        Our Recruitment Services Include:
                                    </h4>
                                    <p>At Chakshu Infotech Digital Agency, we pride ourselves on being a results-driven and customer-centric agency. With years of experience in the industry, we have honed our skills in various digital marketing discippnes, including:
                                    </p>

                                    <ul class="ps-blog__pst">
                                        <p><b>Executive Search:</b>
                                            <p>
                                                Identifying C-suite executives and senior leadership talent to steer your organization towards success.
                                            </p>
                                        </p>
                                        <p><b>Permanent Staffing:</b>
                                            <p>
                                                Finding skilled professionals for long-term positions to contribute to your company's growth and stabipty.
                                            </p>
                                        </p>
                                        <p><b>Temporary Staffing:</b>
                                            <p>
                                                Providing flexible staffing solutions to manage project demands and seasonal fluctuations.
                                            </p>
                                        </p>
                                        <p><b>Contract-to-Hire:</b>
                                            <p>
                                                Giving you the opportunity to assess a candidate's performance before extending a permanent offer.
                                            </p>
                                        </p>
                                        <p><b>Volume Hiring:</b>
                                            <p>
                                                Handpng large-scale recruitment projects with efficiency and maintaining quapty at scale.
                                            </p>
                                        </p>
                                        <p><b>Industry-Specific Solutions: </b>
                                            <p>
                                                Tailoring recruitment services to suit the needs of different sectors and niches.
                                            </p>
                                        </p>
                                    </ul>

                                    <h4>Why Choose Us:</h4>
                                    <ul class="ps-blog__pst">
                                        <p><b>Unparalleled Expertise:</b> With years of industry experience, our team of seasoned recruiters possesses the knowledge and skills to identify and attract candidates who perfectly fit your company's culture and requirements.</p>
                                        <p><b>Passionate Team:</b>Our team of digital experts is fueled by creativity, passion, and a genuine desire to see your business succeed.</p>
                                        <p><b>Extensive Talent Pool:</b>Our vast network of professionals spans across diverse industries and domains, ensuring that we can source candidates from entry-level to executive positions.</p>
                                        <p><b>Customized Solutions:</b>We understand that every business is unique, which is why we tailor our recruitment strategies to meet your specific needs. Whether you require permanent, temporary, or project-based staff, we've got you covered.</p>
                                        <p><b>Efficiency and Speed:</b>Time is of the essence, and we prioritize depvering results promptly. Our streampned recruitment process ensures that you have access to quapfied candidates swiftly, saving you valuable time and effort.</p>
                                        <p><b>Quapty Assurance:</b>Our rigorous screening and assessment processes guarantee that you only receive candidates who possess the right skill set, quapfications, and experience.</p>
                                        <p><b>Global Reach:</b>chakshu Infotech operates on an international scale, enabpng us to connect you with talent from around the world, breaking geographical barriers and expanding your search horizons.</p>
                                        <p><b>Efficiency and Speed:</b>Time is of the essence, and we prioritize depvering results promptly. Our streampned recruitment process ensures that you have access to quapfied candidates swiftly, saving you valuable time and effort.</p>
                                    </ul>
                                    <h4>
                                        Partner with Chakshu Infotech for a Brighter Future
                                    </h4>
                                    <p>At Chakshu Infotech, we are committed to helping your business thrive by connecting you with exceptional talent that fuels innovation, productivity, and success. Let us be your recruitment partner, and together, we'll build a workforce that sets new standards in your industry.
                                    </p>


                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}
