import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation';
export default function Services() {

  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  
  document.title = "Chakshu Infotech In Moradabad"
  return (
    <div>
      

      <div>
        <div className="ps-blog ps-blog--layout2">
          <div className="container">
            <ul className="ps-breadcrumb">
              <li className="ps-breadcrumb__item">
                <a href="/">Home</a>
              </li>
              <li className="ps-breadcrumb__item">
                <a className="active" aria-current="page" href="#">
                  Our Services
                </a>
              </li>
            </ul>
            <h1 className="ps-blog__title" >Our Services<br/>
              <TypeAnimation
                sequence={[

                  1000, 'Website Development', 1000, 'Software Development', 1000, 'Digital Marketing', 1000, 'RECRUITMENT']}
                wrapper="span"
                speed={10}
                style={{ fontSize: '18px', display: 'inline-block', color: "red", marginLeft: "1rem" }}
                repeat={Infinity}
              />

            </h1>
            <div className="tab-content" id="productContent">
              <div
                className="tab-pane fade show active"
                id="tab3-content"
                role="tabpanel"
                aria-labelledby="tab3-blog"
              >
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="ps-blog--latset">
                      <div className="ps-blog__thumbnail img_height">
                        <Link to="/Website-Designing-Company-Moradabad">
                          <img src="assets/img/images/webdes.jpg" alt="alt"  style={{height:"203px"}}/>
                        </Link>
                      </div>
                      <div className="ps-blog__content">
                        <h2 className="ps-courses-name" >Website Development</h2>
                        <p className="ps-blog__date">
                          <b> Web development</b> refers to the process of creating and maintaining websites and web applications. It involves various technologies and programming languages such as <b>HTML, CSS, JavaScript, and backend frameworks.</b> Web developers design user interfaces, implement functionalities, and ensure websites are responsive and user-friendly across different devices and browsers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="ps-blog--latset">
                      <div className="ps-blog__thumbnail img_height">
                        <Link to="/Software-Company-Moradabad">
                          <img src="assets/img/images/Software-Development.jpg" alt="alt" style={{ height: "203px" }} />
                        </Link>
                      </div>
                      <div className="ps-blog__content">
                        <h2 className="ps-courses-name" >Software Development</h2>
                        <p className="ps-blog__date">
                          <b> Software development</b> is the iterative process of designing, coding, testing, and maintaining computer programs or applications to address specific user needs or solve problems. It involves collaboration among developers, project managers, and stakeholders, with the goal of creating high-quality, efficient, and <b>scalable software products</b> for various platforms and industries.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="ps-blog--latset">
                      <div className="ps-blog__thumbnail img_height">
                        <Link to="/Digital-Marketing-Moradabad">
                          <img src="assets/img/images/digital-agency.jpg" alt="alt" style={{ height: "203px" }} />
                        </Link>
                      </div>
                      <div className="ps-blog__content">
                        <h2 className="ps-courses-name" >Digital Marketing</h2>
                        <p className="ps-blog__date">
                          <b> Digital marketing</b> is a strategic approach to promoting products, services, or brands through various online channels. It encompasses a wide range of tactics, including <b>search engine optimization (SEO), </b>social media marketing, email marketing, content creation, and paid advertising.Leveraging technology and data, it targets specific audiences to increase brand visibility, engagement, and conversions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="ps-blog--latset">
                      <div className="ps-blog__thumbnail img_height">
                        <Link to="/Recruitment-Company-Moradabad">
                          <img src="assets/img/images/recruitment.jpg" alt="alt" style={{ height: "203px" }} />
                        </Link>
                      </div>
                      <div className="ps-blog__content">
                        <h2 className="ps-courses-name" >RECRUITMENT</h2>
                        <p className="ps-blog__date">
                          <b> Our recruitment services </b>offer tailored solutions to help businesses find the perfect talent. With a focus on efficiency and expertise, we streamline the hiring process, identify top candidates, and ensure a <b>seamless recruitment experience.</b>Trust us to connect you with skilled professionals who align with your company's culture and objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}
