import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {


    return (
        <div >
            <div>
                <>
                    <div className="aspNetHidden">
                        <input
                            type="hidden"
                            name="__VIEWSTATE"
                            id="__VIEWSTATE"
                            defaultValue="GE/8hbNhKxppBsXfoLaQW6FqGTtul9l1VAOopnz5Pjz74G5vCUZRtaqLgULJzRlLh+pyHN2q9X+AHElXbKoWS1Hz5HiTRLcXLvj+W5+sh6A="
                        />
                    </div>
                    <div className="aspNetHidden">
                        <input
                            type="hidden"
                            name="__VIEWSTATEGENERATOR"
                            id="__VIEWSTATEGENERATOR"
                            defaultValue="CA0B0334"
                        />
                    </div>
                    <div>
                        <header className="ps-header ps-header--1">
                            <div className="ps-header__inner">
                                <div className="ps-header__left">
                                    <div className="ps-logo">
                                        <a

                                            href="/"
                                            title="Software Company in Moradabad"
                                        >
                                            {" "}
                                            <img src="assets/img/chakshuinfotechlogo.png" alt="chakshu infotech logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className="ps-header__center" >
                                    <ul className="menu">
                                        <li className="has-mega-menu" >
                                            <a
                                                href="/"
                                                title="Software company in Moradabad"
                                                style={{color:"red"}}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li className="has-mega-menu">
                                            <Link
                                                to="/Chakshu-infotech"
                                                title="Chakshu Software in Moradabad"
                                            >
                                                About us
                                            </Link>
                                        </li>
                                        <li className="has-mega-menu">
                                            <Link to="/services">Our Service</Link>
                                        </li>
                                        <li className="has-mega-menu ">
                                            <Link to="/products">Our Product</Link>
                                        </li>
                                        <li className="has-mega-menu">
                                            <Link to="/Contact">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="ps-header__right">
                                    <ul className="ps-header__icons">
                                        <li className="ps-header__item open-cart-mini">
                                            <Link className="ps-header__a" to="#">
                                                <img src="assets/img/icon/map.png" style={{ height: "30px" }} />
                                            </Link>
                                            <div className="ps-cart--mini">
                                                <div className="ps-cart__content">
                                                    <div className="mapouter">
                                                        <div className="gmap_canvas">
                                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13975.660205462338!2d78.73983057185984!3d28.871144664871885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390aff6c91f604c5%3A0x4558b02625a6211b!2sChakshu%20Infotech!5e0!3m2!1sen!2sin!4v1701613178800!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                                                            <Link to="https://www.embedgooglemap.net/" />
                                                            <style
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        ".gmap_canvas {overflow:hidden;background:none!important;height:200px;width:100%;}"
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ps-cart__footer">
                                                        <Link
                                                            className="ps-btn ps-btn--rounded ps-btn--dark"
                                                            to="https://www.google.com/maps?ll=28.870303,78.742488&z=13&t=m&hl=en-US&gl=US&mapclient=embed&cid=12793632908891229455"
                                                            target="_blank"
                                                        >
                                                            View Map
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </header>
                        <header className="ps-header ps-header--mobile">
                            <div className="ps-header__inner">
                                <div className="ps-header__left">
                                    <div className="ps-logo">
                                        <Link
                                            to="/"
                                            title="Software Company in Moradabad"
                                        >
                                            <img src="assets/img/chakshuinfotechlogo.png" alt="chakshu infotech logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="ps-header__right">
                                    <ul className="ps-header__icons">
                                        <li className="ps-header__item">
                                            <Link to="tel:8191912633" style={{ fontSize: "22px" }}>
                                                <img src="assets/img/icon/phon.png" style={{ height: "25px" }} />
                                                8630635070
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </header>
                    </div>
                </>
            </div>
        </div>
    )
}
