import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
          <footer className="ps-footer ps-footer--1">
              <div className="container">
                  <div className="ps-footer--service">
                      <div className="container">
                          <div className="ps-footer__row">
                              <p className="ps-footer__item">
                                  <Link
                                      className="ps-footer__a"
                                      to="/Chakshu-infotech"
                                  >
                                      <img src="assets/img/icon/non-contact.svg" alt="" />
                                      <span>About us</span>
                                  </Link>
                              </p>
                              <p className="ps-footer__item">
                                  <Link className="ps-footer__a" to="/services">
                                      <img src="assets/img/icon/wallet.svg" alt="" />
                                      <span>Our Services</span>
                                  </Link>
                              </p>
                              <p className="ps-footer__item">
                                  <Link className="ps-footer__a" to="/products">
                                      <img src="assets/img/icon/cube-line-icon.svg" style={{height:"27px"}}alt="" />
                                      <span>Our Products</span>
                                  </Link>
                              </p>
                              <p className="ps-footer__item">
                                  <Link className="ps-footer__a" to="/Contact">
                                      <img  src="assets/img/icon/security.svg" alt="" />
                                      <span>Contact us </span>
                                  </Link>
                              </p>
                              
                          </div>
                      </div>
                  </div>
                  <div className="ps-footer--bottom">
                      <div className="row">
                          <div className="col-12 col-md-6">
                              <p className="ps-footer__copyright">
                                  COPYRIGHT ©2020 Chakshu infotech. ALL RIGHTS RESERVED.
                              </p>
                          </div>
                          <div className="col-12 col-md-6 text-right">
                              {" "}
                              <p className="ps-footer__contact">
                                  {" "}
                                  <Link className="ps-footer__phone" to="tel:8191912633">
                                      8630635070
                                      
                                  </Link>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>

          <>
              <div className="ps-navigation--footer">
                  <div className="ps-nav__item">
                      <Link to="#" id="open-menu">
                          <img src="assets/img/icon/menu.svg" alt="" />
                      </Link>
                      <Link to="#" id="close-menu">
                          <img src="assets/img/icon/close-red.svg" alt="" />
                      </Link>
                  </div>
                  <div className="ps-nav__item">
                      <Link to="https://wa.me/message/JVU5KQ73TNRIB1">
                          <img src="assets/img/icon/whatsapp.svg" style={{ height: "25px" }} alt="" />
                      </Link>
                  </div>
                  <div className="ps-nav__item">
                      <Link
                          to="https://www.facebook.com/pkscomputercenter"
                          target="_blank"
                          title="Facebook"
                      >
                          <img src="assets/img/icon/facebook.svg" alt="" style={{ height: "25px" }} />
                      </Link>
                  </div>
                  <div className="ps-nav__item">
                      <Link
                          to="https://www.instagram.com/pksmbd/?hl=en"
                          target="_blank"
                          title="Instagram"
                      >
                          <img src="assets/img/icon/instagram.svg" alt="" style={{ height: "25px" }} />
                      </Link>
                  </div>
                  <div className="ps-nav__item">
                      <Link
                          to="https://www.google.com/maps/place/Pks+Computer+Center/@28.8703028,78.7424876,15z/data=!4m2!3m1!1s0x0:0xb18c1d58d9358d0f?sa=X&ved=2ahUKEwiejNrekaf3AhUJSmwGHcIrDKEQ_BJ6BAhVEAU"
                          target="_blank"
                      >
                          <img src="assets/img/icon/google.svg" alt="" style={{ height: "25px" }} />
                      </Link>
                  </div>
              </div>
              <div className="ps-menu--slidebar">
                  <div className="ps-menu__content">
                      <ul className="menu--mobile">
                          <li className="has-mega-menu">
                              <Link
                                  to="/"
                                  title="Computer Center in Moradabad"
                              >
                                  Home
                              </Link>
                          </li>
                          <li className="has-mega-menu">
                              <Link
                                  to="/Chakshu-infotech"
                                  title="Chakshu Software in Moradabad"
                              >
                                  About us
                              </Link>
                          </li>
                          <li className="has-mega-menu">
                              <Link to="/services">Our Services</Link>
                          </li>
                          <li className="has-mega-menu">
                              <Link to="/products">Our Products</Link>
                          </li>
                          <li className="has-mega-menu">
                              <Link to="/Contact">Contact Us</Link>
                          </li>
                      </ul>
                  </div>
              </div>
          </>

          <>
              <button className="btn scroll-top">
                  <i className="fa fa-angle-double-up" />
              </button>
              <div className="ps-preloader" id="preloader">
                  <div className="ps-preloader-section ps-preloader-left" />
                  <div className="ps-preloader-section ps-preloader-right" />
              </div>
          </>

    </div>
  )
}
