import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation';



export default function Product() {
    useEffect(() => {
        
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    document.title ="Software Company In Moradabad"
  return (
    <div>
          <div className="ps-blog ps-blog--layout2">
              <div className="container">
                  <ul className="ps-breadcrumb">
                      <li className="ps-breadcrumb__item">
                          <a href="/">Home</a>
                      </li>
                      <li className="ps-breadcrumb__item">
                          <a className="active" aria-current="page" href="#">
                              Our Products
                          </a>
                      </li>
                  </ul>
                  <h1 className="ps-blog__title" >Our Products<br/>
                      <TypeAnimation
                          sequence={[
                              
                              1000, 'SCHOOL MANAGENENT SOFTWARE ', 1000, 'E-COMMERCE', 1000,'BILLING SOFTWARE']}
                          wrapper="span"
                          speed={10}
                          style={{ fontSize: '18px', display: 'inline-block',color:"red",marginLeft:"1rem" }}
                          repeat={Infinity}
                      />
                  
                  </h1>
                  <div className="tab-content" id="productContent">
                      <div
                          className="tab-pane fade show active"
                          id="tab3-content"
                          role="tabpanel"
                          aria-labelledby="tab3-blog"
                      >
                          <div className="row">
                              <div className="col-12 col-md-6 col-lg-4">
                                  <div className="ps-blog--latset">
                                      <div className="ps-blog__thumbnail img_height">
                                          <Link to="/School-Management-Software">
                                              <img src="assets/img/images/schoolimg.png" alt="alt" style={{ height: "246px" }} />
                                          </Link>
                                      </div>
                                      <div className="ps-blog__content">
                                          <h2 className="ps-courses-name" >SCHOOL MANAGENENT SOFTWARE</h2>
                                          <p className="ps-blog__date">
                                              <b> School management</b> is a complex task that involves handling student records, timetables, attendance, fees, and much more. Traditional paperwork and manual record-keeping methods can be time-consuming and error-prone. However, with the advent of<b> School Management Software,</b> educational institutions can efficiently manage their administrative tasks and enhance overall productivity. In this article, we'll explore the key features and benefits of school management software.
                                          </p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-4">
                                  <div className="ps-blog--latset">
                                      <div className="ps-blog__thumbnail img_height">
                                          <Link to="/E-commerce">
                                              <img src="assets/img/images/ecommerce.png" alt="alt" style={{ height: "246px" }} />
                                          </Link>
                                      </div>
                                      <div className="ps-blog__content">
                                          <h2 className="ps-courses-name" >E-COMMERCE</h2>
                                          <p className="ps-blog__date">
                                              <b>E-commerce software </b>is the backbone of online retail, enabling businesses to establish, manage, and grow their digital storefronts. Whether you're a small startup or a well-established brand, the right <b>e-commerce software</b> can make all the difference in your online success. In this short content, we'll explore the key features and benefits of e-commerce software as well as highlight some popular options available in the market
                                          </p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-4">
                                  <div className="ps-blog--latset">
                                      <div className="ps-blog__thumbnail img_height">
                                          <Link to="/Billing-Software">
                                              <img src="assets/img/images/billing.jpg" alt="alt" style={{ height: "246px" }} />
                                          </Link>
                                      </div>
                                      <div className="ps-blog__content">
                                          <h2 className="ps-courses-name" >BILLING SOFTWARE</h2>
                                          <p className="ps-blog__date">
                                              Managing your business finances and keeping track of invoices can be a daunting task. Chakshu <b>Billing Software </b>is here to streamline your billing process, making it easier than ever to create, send, and manage invoices.Whether you run a small business or a large enterprise, <u>our software is designed to meet your billing needs efficiently and accurately. </u>That's where EasyBilling Software comes in – a user-friendly, versatile billing solution designed to streamline your billing process.
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

    </div>
  )
}
