import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function About() {

  document.title = "Chakshu Infotech In Moradabad"

  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  
  return (
    <div>
      <>
        <div className="ps-blog ps-blog--layout1">
          <div className="container">
            <ul className="ps-breadcrumb">
              <li className="ps-breadcrumb__item">
                <a href="/">Home</a>
              </li>
              <li className="ps-breadcrumb__item">
                <Link className="active" aria-current="page" to="#">
                  About us
                </Link>
              </li>
            </ul>
            <h1 className="ps-blog__title">Chakshu Infotech in Moradabad</h1>
            <div className="row">
              <div className="col-12">
                <section className="ps-post--grid">
                  <div className="ps-post__thumbnail">
                    <img
                      src="assets/img/banners/banner1.jpg"
                      alt="COMPUTER INSTITUTE IN MORADABAD"
                    />
                  </div>
                  <div className="ps-post__content">
                    <h2 className="ps-post__title">
                      <a>
                        Chakshu Infotech is the best Software Company in Moradabad. 
                      </a>
                    </h2>
                    <p className="ps-post__date">
                      Chakshu Infotech, established in the year 2020 and serving the Customer with quality Services in IT Industry. At Chakshu Infotech Software Company, we pride ourselves on delivering innovative solutions to meet your business needs. Our cutting-edge software comes packed with an array of features designed to streamline your operations, enhance productivity, and drive success. Here are some of the key features that set us apart:
                    </p>
                  </div>
                  <h2>ABOUT US</h2>
                  <p>
                    Chakshu Infotech, established in the year 2020 and serving the Customer with quality Services in IT Industry. At Chakshu Infotech Software Company, we pride ourselves on delivering innovative solutions to meet your business needs. Our cutting-edge software comes packed with an array of features designed to streamline your operations, enhance productivity, and drive success. Here are some of the key features that set us apart:
                  </p>


                  <p>
                    *<b>Scalable Solutions:</b>Our software is built with scalability in mind, ensuring it can grow alongside your business. Whether you're a startup or an enterprise, our solutions can adapt to your evolving requirements<br />

                    *<b>User-Friendly Interface:</b> We understand the importance of simplicity and efficiency. Our software boasts an intuitive user interface that minimizes the learning curve, enabling your team to hit the ground running.<br />

                    * <b>Customization Options:</b> Every business is unique, and we recognize that. Our software is highly customizable, allowing you to tailor it to your specific workflows and business processes.<br />

                    * <b>Data Security:</b> Protecting your valuable data is our top priority. Our software implements robust security measures to safeguard your information from potential threats.<br />

                    * <b>Cloud Compatibility:</b> Embrace the power of cloud technology with our software solutions. Access your data and tools from anywhere, anytime, and enjoy seamless collaboration across teams.<br></br>

                    * <b>Automation Capabilities:</b> Save time and reduce manual effort with our automation features. From routine tasks to complex processes, our software can automate repetitive actions, increasing efficiency.<br></br>

                    * <b>Integration Flexibility:</b> Our software seamlessly integrates with other essential tools and systems you already use, enhancing overall functionality and reducing data silos.<br></br>

                    * <b>Real-Time Analytics:</b> Make data-driven decisions with our real-time analytics and reporting features. Monitor key performance indicators and gain valuable insights into your business performance.<br></br>

                    * <b>Mobile Accessibility:</b> Stay connected and productive on the go. Our software is optimized for mobile devices, ensuring you can manage tasks from your smartphone or tablet.<br></br>

                    * <b>24/7 Support:</b> We are committed to providing exceptional customer service. Our dedicated support team is available round-the-clock to address your queries and resolve any issues promptly.<br></br>

                    * <b>Regular Updates:</b> Technology never stands still, and neither do we. We continuously improve our software through updates, adding new features and enhancements to keep you at the forefront of innovation.<br></br>

                  </p>
                  <p>
                    With <b>Chakshu Infotech Software Company,</b> you can take your business to new heights with powerful, reliable, and easy-to-use software solutions. Let us be your partner in success. Get in touch today to explore how our software can transform your operations and drive growth.
                  </p>

                  <h4>We provide following Services.</h4>
                  * Custom Software Development<br></br>
                  * Web Development<br></br>
                  * Mobile App Development<br></br>
                  * Digital Marketing<br></br>
                  * Graphic designing<br></br>
                  * Software Integration <br></br>
                  * UI/UX Design <br></br>
                  * Quality Assurance and Testing <br></br>
                  * E-commerce Solutions<br></br>
                  * Data Analytics and Business Intelligence <br></br>
                  * Cloud Services<br></br>
                  * Maintenance and Support<br></br>
                </section>
              </div>
            </div>
          </div>
        </div>

      </>

    </div>
  )
}
